import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProtocolosService {

  httpOptions: any;
  customerId: any;
  storage: Storage;
  token: any;

  constructor(private http: HttpClient) {
    this.storage = window.sessionStorage;

    const item = this.storage.getItem('token');
    this.token = item ? JSON.parse(item) : {};

    const itemUser = this.storage.getItem('user');
    const user = itemUser ? JSON.parse(itemUser) : {};
    this.customerId = user.customerId;
  }

  getProtocols(options: { name?: any, date?: any, page?: any, size?: any }, modalResquest?: boolean) {
    let params = new HttpParams();

    if (options.name) {
      params = params.set('name', options.name);
    }

    if (options.date) {
      params = params.set('createdAt', options.date);
    }

    if (options.page) {
      params = params.set('page', options.page);
    }

    if (options.size) {
      params = params.set('size', options.size);
    }

    if (modalResquest)
      params = params.set('onlyActive', true);
    else
      params = params.set('onlyActive', false);

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params,
    };

    return this.http.get(`${environment.api}/protocol-service/list/filtered/${this.customerId}`, httpOptions);
  }

  getProtocolsByProvider(options: { name?: any, date?: any, page?: any, size?: any }, customerId: any) {
    let params = new HttpParams();

    if (options.name) {
      params = params.set('name', options.name);
    }

    if (options.date) {
      params = params.set('createdAt', options.date);
    }

    if (options.page) {
      params = params.set('page', options.page);
    }

    if (options.size) {
      params = params.set('size', options.size);
    }

    params = params.set('onlyActive', false);

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params,
    };

    return this.http.get(`${environment.api}/protocol-service/list/filtered/${customerId}`, httpOptions);
  }

  getProtocolByHash(hash?: any) {
    let params = new HttpParams();

    params = params.set('onlyActive', false);

    if (hash) {
      params = params.set('id', hash);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params,
    };

    return this.http.get(`${environment.api}/protocol-service/list/filtered/${this.customerId}`, httpOptions);
  }

  getProtocolByHashByProvider(hash?: any, customerId?: any) {
    let params = new HttpParams();

    params = params.set('onlyActive', false);

    if (hash) {
      params = params.set('id', hash);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params,
    };

    return this.http.get(`${environment.api}/protocol-service/list/filtered/${customerId}`, httpOptions);
  }

  getCriterias(criteriaCode?: any) {
    let params = new HttpParams();

    if (criteriaCode) {
      params = params.set('criteriaCode', criteriaCode);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params
    };

    return this.http.get(`${environment.api}/criteria-service/list/filtered`, httpOptions);
  }

  inactivateProtocol(id: any) {
    let params = new HttpParams();

    if (id) {
      params = params.set('id', id);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params
    };

    return this.http.get(`${environment.api}/protocol-service/inactivate-protocol`, httpOptions);
  }

  activateProtocol(id: any) {
    let params = new HttpParams();

    if (id) {
      params = params.set('id', id);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params
    };

    return this.http.get(`${environment.api}/protocol-service/activate-protocol`, httpOptions);
  }

  getProtocolsOnlyActive(options: { name?: any, date?: any, page?: any, size?: any }) {
    let params = new HttpParams();


    if (options.name) {
      params = params.set('name', options.name);
    }

    if (options.date) {
      params = params.set('createdAt', options.date);
    }

    if (options.page) {
      params = params.set('page', options.page);
    }

    if (options.size) {
      params = params.set('size', options.size);
    }

    params = params.set('onlyActive', true);



    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params,
    };

    return this.http.get(`${environment.api}/protocol-service/list/filtered/${this.customerId}`, httpOptions);
  }

  registerProtocol(body: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      })
    };

    return this.http.post(`${environment.api}/protocol-service/register?creationToken=${this.customerId}`, body, httpOptions);
  }

  updateProtocol(body: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      })
    };

    return this.http.put(`${environment.api}/protocol-service/update?creationToken=${this.customerId}`, body, httpOptions);
  }

  showHistory(id: any) {
    let params = new HttpParams();

    params = params.set('id', id);

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params
    };

    return this.http.get(`${environment.api}/protocol-service/protocol-history`, httpOptions);

  }

  public removeWhiteList(id: any, customerId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };

    let httpRequest: any = httpOptions;
    httpRequest.responseType = 'text';

    return this.http.get(`${environment.api}/protocol-service/remove-token-from-white-list/${customerId}?id=${id}`, httpRequest);
  }

  public addWhiteList(id: any, customerId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };

    let httpRequest: any = httpOptions;
    httpRequest.responseType = 'text';

    return this.http.get(`${environment.api}/protocol-service/add-token-to-white-list/${customerId}?id=${id}`, httpRequest);
  }

}
