<div class="wrapper" *ngIf="!showLogin" [ngClass]="getClasses()">


  <app-header></app-header>


  <!--   SIDEBAR -->
  <div class="sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <!--   CONTENT -->
  <div class="section">
    <router-outlet></router-outlet>
  </div>
</div>

<ng-container *ngIf="showLogin">
  <router-outlet></router-outlet>
</ng-container>